<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>ดูรายการออเดอร์</h3>
      </v-col>
      <v-col cols="4">
        <span>ค้นหาด้วยชื่อ</span>
        <v-text-field
          class="right-input"
          id="donut"
          label=""
          v-model="customerName"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <span>งวดลอตเตอรี่</span>
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="rewardDate"
                  label="วันที่ออก"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                  clearable
                  @click:clear="rewardDate = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="rewardDate"
                @input="menufrom = false"
                @click="$refs.menufrom.save(rewardDate)"
              ></v-date-picker>
              <!-- <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="lotteryDate"
                        label="วันที่ออก"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="lotteryDate"
                      no-title
                      scrollable
                      :min="firstDate"
                    >
                      <v-btn text color="primary" @click="menufrom = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufrom.save(lotteryDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker> -->
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-btn @click="clear()" class="mr-4">เคลียร์</v-btn>
        <v-btn @click="searchAllOrder()" color="primary">ค้นหา</v-btn>
      </v-col>
      <v-tabs centered>
        <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
          item.text
        }}</v-tab>
      </v-tabs>

      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มหมวดหมู่</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }}</span>
            </template>
            <!-- <template v-slot:[`item.name`]="{ item }">
              <span> {{ item.user.firstName }} {{ item.user.lastName }} </span>
            </template> -->
            <template v-slot:[`item.isDelivery`]="{ item }">
              <span v-if="item.isDelivery">จัดส่งไปรษณีย์ EMS</span>
              <span v-if="!item.isDelivery">ฝากลอตเตอรี่ไว้กับเรา</span>
            </template>

            <!-- { text: "มีปัญหา", value: "มีปัญหา" },
        { text: "ยกเลิก", value: "ยกเลิก" },
        // { text: "อนุมัติ/ยืนยันสลิปถูกต้อง", value: "สั่งซื้อสำเร็จ" },
        { text: "รอการชำระเงิน", value: "รอการชำระเงิน" },
        { text: "ชำระเงินสำเร็จ", value: "ชำระเงินสำเร็จ" },
        { text: "สั่งซื้อสำเร็จ(อัพโหลดตั๋วแล้ว)", value: "สั่งซื้อสำเร็จ" }, -->
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'PENDING'" color="orange" dark>
                รอการชำระเงิน
              </v-chip>
              <v-chip v-if="item.status == 'ชำระเงินสำเร็จ'" color="green" dark>
                ชำระเงินสำเร็จ
              </v-chip>
              <!-- <v-chip v-if="item.status == 'ส่งEMSแล้ว'" color="blue" dark>
                ส่งEMSแล้ว
              </v-chip> -->
              <v-chip v-if="item.status == 'มีปัญหา'" color="red" dark>
                มีปัญหา
              </v-chip>
              <v-chip v-if="item.status == 'ยกเลิก'" color="brown" dark>
                ยกเลิก
              </v-chip>
              <!-- <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              > -->
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewOrder(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateOrder(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon @click="DeleteOrder(item)">mdi-delete</v-icon> -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from "moment";
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersOrder: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หมายเลขคำสั่งซื้อ", value: "transactionNo", align: "center" },
        { text: "วันที่สั่ง", value: "createdAt", align: "center" },
        { text: "ชื่อผู้สั่ง", value: "fullname", align: "center" },
        { text: "ราคา", value: "totalPrice", align: "center" },
        { text: "เลขที่ซื้อ", value: "buyLotNo", align: "center" },
        { text: "สถานะการจัดส่ง", value: "isDelivery", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      // count: 1
      // couponCode: (...)
      // createdAt: (...)
      // deliveryAddress: (...)
      // deliveryStatus: (...)
      // id: (...)
      // isDelivery: (...)
      // lotteryDate: (...)
      // lotteryTransaction: (...)
      // slipImg: (...)
      // status: (...)
      // totalPrice: (...)
      // transactionNo: (...)
      // updatedAt: (...)
      // user: Object
      // userId: (...)
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      tabitems: [
        //  <v-chip v-if="item.status == 'PENDING'" color="orange" dark>
        //         รอการชำระเงิน
        //       </v-chip>
        //       <v-chip v-if="item.status == 'ชำระเงินสำเร็จ'" color="green" dark>
        //         ชำระเงินสำเร็จ
        //       </v-chip>
        //       <!-- <v-chip v-if="item.status == 'ส่งEMSแล้ว'" color="blue" dark>
        //         ส่งEMSแล้ว
        //       </v-chip> -->
        //       <v-chip v-if="item.status == 'มีปัญหา'" color="red" dark>
        //         มีปัญหา
        //       </v-chip>
        //       <v-chip v-if="item.status == 'ยกเลิก'" color="brown" dark>
        //         ยกเลิก
        //       </v-chip>
        { text: "ทั้งหมด", key: "" },
        { text: "รอการชำระเงิน", key: "PENDING" },
        { text: "ชำระเงินสำเร็จ", key: "ชำระเงินสำเร็จ" },
        {
          text: "มีปัญหา",
          key: "มีปัญหา",
        },
        {
          text: "ยกเลิก",
          key: "ยกเลิก",
        },
      ],
      search: "",
      count: 0,
      menufrom: false,
      rewardDate: "",
      customerName: "",
    };
  },
  created() {
    this.getAllOrder();
    // setInterval(() => {
    //   this.getAllOrder();
    // }, 10000);
  },
  methods: {
    clear() {
      this.rewardDate = "";
      this.customerName = "";
      this.getAllOrder();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async searchAllOrder() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAll` +
          "?customerName=" +
          this.customerName +
          "&rewardDate=" +
          this.rewardDate,
        auth
      );
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].fullname =
          this.items[i].user.firstName + " " + this.items[i].user.lastName;

        this.items[i].buyLotNo = "";
        this.items[i].lotteryTransaction.forEach((trans) => {
          // console.log(trans)
          this.items[i].buyLotNo += trans.lottery.lotteryNo + "\n";
        });
      }
      console.log("response", response.data.data);
    },
    async tab(val) {
      console.log(val.key);
      // /orders?status=รอการยืนยัน
      if (val.key == "") {
        this.getAllOrder();
      } else {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        console.log(user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transaction/findAll?status=` +
            val.key +
            "&customerName=" +
            this.customerName +
            "&rewardDate=" +
            this.rewardDate,
          auth
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
          this.items[i].fullname =
            this.items[i].user.firstName + " " + this.items[i].user.lastName;

          this.items[i].buyLotNo = "";
          this.items[i].lotteryTransaction.forEach((trans) => {
            // console.log(trans)
            this.items[i].buyLotNo += trans.lottery.lotteryNo + "\n";
          });
        }
        console.log("response", response.data.data);
      }
    },
    async getAllOrder() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAll` +
          "?customerName=" +
          this.customerName +
          "&rewardDate=" +
          this.rewardDate,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllOrder", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
        this.items[i].fullname =
          this.items[i].user.firstName + " " + this.items[i].user.lastName;
        this.items[i].buyLotNo = "";
        this.items[i].lotteryTransaction.forEach((trans) => {
          // console.log(trans)
          this.items[i].buyLotNo += trans.lottery.lotteryNo + "\n";
        });
      }

      console.log(this.items);
    },
    UpdateOrder(val) {
      localStorage.setItem("orderdata", Encode.encode(val));
      this.$router.push("EditOrder");
      // window.open("EditOrder");
    },
    async DeleteOrder(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("DDLotAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/transaction/` + val.id,
            auth
          );
          console.log(response);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllOrder();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcategory");
    },
  },
};
</script>
<style scoped>
.right-input::v-deep input {
  text-align: end;
}
</style>
