var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("ดูรายการออเดอร์")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('span',[_vm._v("ค้นหาด้วยชื่อ")]),_c('v-text-field',{staticClass:"right-input",attrs:{"id":"donut","label":"","outlined":"","dense":""},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v("งวดลอตเตอรี่")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"วันที่ออก","append-icon":"mdi-calendar","readonly":"","dense":"","solo":"","clearable":""},on:{"click:clear":function($event){_vm.rewardDate = null}},model:{value:(_vm.rewardDate),callback:function ($$v) {_vm.rewardDate=$$v},expression:"rewardDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menufrom),callback:function ($$v) {_vm.menufrom=$$v},expression:"menufrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menufrom = false},"click":function($event){return _vm.$refs.menufrom.save(_vm.rewardDate)}},model:{value:(_vm.rewardDate),callback:function ($$v) {_vm.rewardDate=$$v},expression:"rewardDate"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mr-4",on:{"click":function($event){return _vm.clear()}}},[_vm._v("เคลียร์")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.searchAllOrder()}}},[_vm._v("ค้นหา")])],1),_c('v-tabs',{attrs:{"centered":""}},_vm._l((_vm.tabitems),function(item){return _c('v-tab',{key:item.key,on:{"click":function($event){return _vm.tab(item)}}},[_vm._v(_vm._s(item.text))])}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersOrder,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt)))])]}},{key:"item.isDelivery",fn:function(ref){
var item = ref.item;
return [(item.isDelivery)?_c('span',[_vm._v("จัดส่งไปรษณีย์ EMS")]):_vm._e(),(!item.isDelivery)?_c('span',[_vm._v("ฝากลอตเตอรี่ไว้กับเรา")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'PENDING')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" รอการชำระเงิน ")]):_vm._e(),(item.status == 'ชำระเงินสำเร็จ')?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" ชำระเงินสำเร็จ ")]):_vm._e(),(item.status == 'มีปัญหา')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" มีปัญหา ")]):_vm._e(),(item.status == 'ยกเลิก')?_c('v-chip',{attrs:{"color":"brown","dark":""}},[_vm._v(" ยกเลิก ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateOrder(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }